/* You can add global styles to this file, and also import other style files */
@use './theme/typo' as *;
@use './theme/palette' as *;

@use "@fortawesome/fontawesome-free/scss/fontawesome.scss" as fa;
@use "@fortawesome/fontawesome-free/scss/solid.scss" as fa-solid;
@use "@fortawesome/fontawesome-free/scss/brands.scss" as fa-brands;

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;

  color: $svnl-secondary-color;
}

.svnl-mb-1-rem {
  margin-bottom: 1rem;
}

.svnl-mr-1-rem {
  margin-right: 1rem;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
  align-items: center;
}

.svnl-checkbox-cont .mat-checkbox-layout {
  white-space: unset;
}

.mat-accordion .mat-expansion-panel {
  border-top: 1px solid $svnl-gradient-to-color;
  border-radius: 0 !important;
}

.svnl-card-action {
  margin-block-start: 1.33em;
  float: right;
}

.svnl-card-actions {
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  display: flex;
  justify-content: space-between;
}

div.svnl-warning {
  border: 1px solid $svnl-warn-color;
  border-radius: 3px;
  padding: 0.75rem;
  text-align: center;
  color: $svnl-warn-color;
  background-color: $svnl-warn-background-color;
}

div.svnl-alert {
  border-radius: 3px;
  padding: 0.75rem;
  text-align: center;
  max-width: 50rem;
  margin-top: 2rem;

  &.svnl-error {
    border: 1px solid $svnl-error-color;
    color: $svnl-error-color;
    background-color: $svnl-error-background-color;
  }

  &.svnl-warning {
    border: 1px solid $svnl-warn-color;
    color: $svnl-warn-color;
    background-color: $svnl-warn-background-color;
  }

  &.svnl-info {
    border: 1px solid $svnl-primary-color;
    color: $svnl-primary-color;
    background-color: $svnl-info-background-color;
  }
}

/** DIALOGS **/
.mat-dialog-content{
  color: $svnl-secondary-color;
}

/** BACKGROUND **/
@media (min-width: 1000px) {
  body {
    background: url('/assets/images/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

@media (max-width: 1000px) {
  body {
    background: url('/assets/images/bg-1024.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}


/** Font awesome **/
.fa.invalid {
  color: darkgrey;
  cursor: not-allowed;
}

.fa.fa-check-circle.valid {
  color: green;
}

.fa.fa-check-circle.invalid {
  color: red;
}

.fa.fa-user-circle.invalid {
  color: darkgrey;
  cursor: not-allowed;
}

.fa.fa-check-circle.empty {
  color: darkgrey;
}
