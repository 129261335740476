@use "@angular/material" as mat;
@use 'sass:map';
@use '@fireflysemantics/sass-logger' as logger;
@use 'palette' as *;
@use 'typo' as *;

$svnl-theme: mat.define-light-theme((
  color: (
    primary: $svnl-primary,
    accent: $svnl-accent,
    warn: $svnl-warn,
  ),
  typography: $svnl-typography
));

h1, h2, h3, h4, h5 {
  color: $svnl-secondary-color;
}

$material-all-components-theme-overrides: (
  'color': (
    'foreground': (
      'divider': $svnl-primary-color,
    ),
  ),
);

$material-expansion-theme-overrides: (
  'color': (
    'foreground': (
      'text': $svnl-secondary-color,
    ),
  ),
);

$material-radio-theme-overrides: (
  'color': (
    'foreground': (
      'secondary-text': $svnl-secondary-color,
    ),
  ),
);

@include mat.core-theme($svnl-theme);
@include mat.autocomplete-theme(map.deep-merge($svnl-theme, $material-all-components-theme-overrides));
@include mat.bottom-sheet-theme(map.deep-merge($svnl-theme, $material-all-components-theme-overrides));
@include mat.button-theme(map.deep-merge($svnl-theme, $material-all-components-theme-overrides));
@include mat.checkbox-theme(map.deep-merge($svnl-theme, $material-all-components-theme-overrides));
@include mat.datepicker-theme(map.deep-merge($svnl-theme, $material-all-components-theme-overrides));
@include mat.dialog-theme(map.deep-merge($svnl-theme, $material-all-components-theme-overrides));
@include mat.divider-theme(map.deep-merge($svnl-theme, $material-all-components-theme-overrides));
@include mat.expansion-theme(map.deep-merge($svnl-theme, $material-expansion-theme-overrides));
@include mat.form-field-theme(map.deep-merge($svnl-theme, $material-all-components-theme-overrides));
@include mat.icon-theme(map.deep-merge($svnl-theme, $material-all-components-theme-overrides));
@include mat.input-theme(map.deep-merge($svnl-theme, $material-all-components-theme-overrides));
@include mat.radio-color(map.deep-merge($svnl-theme, $material-radio-theme-overrides));
@include mat.select-theme(map.deep-merge($svnl-theme, $material-all-components-theme-overrides));
@include mat.slide-toggle-theme(map.deep-merge($svnl-theme, $material-all-components-theme-overrides));
@include mat.toolbar-theme(map.deep-merge($svnl-theme, $material-all-components-theme-overrides));


